//============================================
// Formular
//============================================



// Bullet Elements
let formData = {
	slide: 0,
	acitvatedSlide: 0,
	isForMe: true,
	isWearing: true,
	bullets: [],
	timer: null,

	updateProgressBar: () => {
		const length = Math.round(100 / (formData.length) * formData.slide)
		if (document.getElementById('progress')) {
			document.querySelector('#progress > div').style.width = length + '%'
		}
	},

	bulletNavigation: (q) => { 
		const questions = document.querySelectorAll(q)
		for (let i = 0; i < questions.length; i++) { 
			formData.bullets.push({id: i, active: i <= formData.slide, activated: i <= formData.activated, disabled: false})
		}
		formData.length = questions.length
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

	updateBulletNavigation: () => {
		const bulletContainer = document.getElementById('bullets') ? document.getElementById('bullets') : false
		if (bulletContainer) {
			bulletContainer.innerHTML = ""
			formData.bullets.map(bullet => {			
				bullet.id <= formData.slide ? bullet.active = true : bullet.active = false
				bullet.id <= formData.acitvatedSlide ? bullet.activated = true : bullet.activated = false
				bulletContainer.innerHTML += `<div class="bullet${bullet.disabled ?' disabled' : ''}${bullet.active ? ' active' : ''}${bullet.activated ?' activated' : ''}" ${formData.acitvatedSlide >= bullet.id && !bullet.disabled ? 'onclick="getSlide('+bullet.id+')"' : ''}></div>`
			})
			
		}
	},

	resetBulletNavigation: () => {
		const bulletContainer = document.getElementById('bullets') ? document.getElementById('bullets') : false
		if (bulletContainer) {
			bulletContainer.innerHTML = ""
			formData.bullets.map(bullet => {			
				bullet.id <= formData.slide ? bullet.active = true : bullet.active = false
				bullet.id <= formData.acitvatedSlide ? bullet.activated = true : bullet.activated = false
				bulletContainer.innerHTML += `<div class="bullet${bullet.disabled ?' disabled' : ''}${bullet.active ? ' active' : ''}${bullet.activated ? '' : ''}" ${formData.acitvatedSlide >= bullet.id && !bullet.disabled ? 'onclick="getSlide('+bullet.id+')"' : ''}></div>`
			})
			
		}
	},

	disableBulletClick: (id) => { 
		formData.bullets[id].disabled = true
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

	enableBulletClick: (id) => { 
		formData.bullets[id].disabled = false
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	},

		idleFunction: (el) => { 
						const rndInt = Math.floor(Math.random() * (8 - 1) + 1)
						const num = rndInt > 1 ? 'n' : ''
						decreaseCounter('.counter', 'amp__hoergeraete_counter')
							el.innerHTML = `Letzte Anfrage vor <strong>${rndInt} Minute${num}</strong>`;
							formData.idle = true;
	},

	setInteractionTimer: (id) => { 
		const container = document.getElementById(id)
		container.innerHTML = "";
		formData.timer = setTimeout(() => formData.idleFunction(container), 10000)		
	},

	clearInteractionTimer: () => { 
		clearTimeout(formData.timer)
	}

}

// if (document.querySelectorAll('[name="client"]').length > 0) { 
		
// }
if (document.getElementById("form")) {
	const client = document.querySelectorAll('[name="client"]')
	const studie = document.querySelectorAll('[name="studie"]')
	const form = document.getElementById("form");
	const questions = form.querySelectorAll(".question");
	const cbWer = form.querySelectorAll('[name="wer"]');
	const cbPKV = form.querySelectorAll('[name="pkv"]');
	const cbMuehe = form.querySelectorAll('[name="muehe"]');
	const cbProbleme = form.querySelectorAll('[name="probleme"]') ? form.querySelectorAll('[name="probleme"]') : null 
	const cbAlterHG = form.querySelectorAll('[name="alterhoergeraete"]');
	const btnAlter = form.querySelector('[data-name="alter"]') ? form.querySelector('[data-name="alter"]') : false;
	const btnPlz = form.querySelector('[data-name="plz"]');
	const btnCode = form.querySelector('[data-name="code"]') ? form.querySelector('[data-name="code"]') : false;
	const btnTel = form.querySelector('[data-name="tel"]');
	const btnName = form.querySelector('[data-name="name"]');
	const btnMail = form.querySelector("#next__submit");
	const route1 = form.querySelectorAll('.route_1')
	const route2 = form.querySelectorAll('.route_2')

	//Inputs
	const alter = document.getElementById('alter') ? document.getElementById('alter') : false
	const codeField = document.getElementById('code') ? document.getElementById('code') : '' 
	const plz = document.getElementById('plz')
	const tel = document.getElementById('tel')
	const vorname = document.getElementById('vorname')
	const nachname = document.getElementById('name')
	const mail = document.getElementById('mail')


	const questionArray = [];

	questions.forEach(q => questionArray.push(q));


	const getSlide = (slide) => {
		formData.slide = slide
		questionArray.forEach(q => q.classList.remove('active'))
		questionArray[slide].classList.add('active')
		formData.updateBulletNavigation()
		formData.updateProgressBar()
		
	}

	questionArray[formData.slide].classList.add("active");

	const showQuestion = i => { 
		formData.slide = i
		questions.forEach(q => { 
			q.style.left = formData.slide * -100 + '%'
			q.classList.remove('active')
		})
		questions[formData.slide].classList.add('active')
		const inputs = questions[formData.slide].querySelectorAll('input')
		inputs.forEach(i => { 
			i.tabIndex = 1
		})
		formData.updateBulletNavigation()
		formData.updateProgressBar()


	}
	const setQuestion = () => { 
		formData.slide = 0
		questions.forEach(q => { 
			q.style.left = formData.slide * -100 + '%'
			q.classList.remove('active')
		})
		questions[formData.slide].classList.add('active')
		const inputs = questions[formData.slide].querySelectorAll('input')
		inputs.forEach(i => { 
			i.tabIndex = 1
		})
		formData.resetBulletNavigation()
		formData.updateProgressBar()

	}

	const nextQuestion = () => {
		formData.slide++;
		formData.slide > formData.acitvatedSlide ? formData.acitvatedSlide++: null
		questions.forEach(q => { 
			q.style.left = formData.slide * -100 + '%'
			q.classList.remove('active')
		})
		questions[formData.slide].classList.add('active')
		const inputs = questions[formData.slide].querySelectorAll('input')
		inputs.forEach(i => { 
			i.tabIndex = 1
		})
		formData.updateBulletNavigation()
		formData.updateProgressBar()
	};

		const isRadioChecked = (name, err, msg) => {
			const radioGroup = form.querySelectorAll("[name=" + name + "]");
			const errorMsg = document.getElementById(err)

			let isChecked = false
				radioGroup.forEach(r => {			
					if (r.checked) {
						isChecked = true
					}
				})
			if (!isChecked) {
				errorMsg.classList.add('alert')
				errorMsg.innerHTML = msg
				return isChecked				
			}
			errorMsg.classList.remove('alert')
			errorMsg.innerHTML = ""
			return isChecked
		};

		const checkCharLength = (el, err, val, msg) => {
				const input = document.getElementById(el).value;
				const error = document.getElementById(err);
				if (input.length < val) {
				error.classList.add("alert");
				error.innerHTML = msg;
				} else {
				error.classList.remove("alert");
				error.innerHTML = "";
				nextQuestion();
				}
		};
		const checkStringLength = (el, err, val, msg) => {
				const input = document.getElementById(el).value;
				const error = document.getElementById(err);
				
				if (/\d/.test(input) === false) {
				if (input.length < val) {
						error.classList.add("alert");
						error.innerHTML = msg;
						return false
				} else {
						error.classList.remove("alert");
						error.innerHTML = "";
						return true
				}

				} else { 
				error.classList.add("alert");
				error.innerHTML = msg;

				}		
		};

	
	
		const checkOption = (el, err, msg) => {
				const input = document.getElementById(el).value;
				const error = document.getElementById(err);
				
				if (/\d/.test(input) === false) {
				if (input === "Anrede") {
						error.classList.add("alert");
						error.innerHTML = msg;
						return false
				} else {
						error.classList.remove("alert");
						error.innerHTML = "";
						return true
				}

				} else { 
					error.classList.add("alert");
					error.innerHTML = msg;

				}		
		};

		const checkStringLengthInput = (el, val) => {
				const string = document.getElementById(el).value;
				const input = document.getElementById(el)
				if (/\d/.test(string) === false && string.length >= val) {
					input.parentNode.classList.add('success')
				} else { 
					input.parentNode.classList.remove('success')
				}

				

		};
	
		
		const isValidString = (el, val) => {
			const input = document.getElementById(el).value;		
			if (/\d/.test(input) === true || input.length < val) {
				return false
			} else { 
				return true
			}		
		};
	
	const checkHoerzuCode = (el) => {
		const input = document.getElementById(el).value
		let isValid = false
		const error = document.getElementById('alertCode')
		const allowedValues = ["hören 21", "hören21", "hoeren 21", "hoeren21"]
		allowedValues.map(v => {
			if (input.toLowerCase() === v) {
				isValid = true
			}
		})
		if (isValid) {
			nextQuestion()
		} else {
			error.classList.add('alert')
			error.innerHTML = 'Aktionscode ungültig'
		} 

	 }
	

		/* =============================
		 * Kundenvalidierung
		============================= */


function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays*24*60*60*1000));
		var expires = "expires="+ d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}
		const alertNoClient = () => { 
				const modal = document.getElementById('form--modal')
				modal.classList.add('active')
				setCookie('amp_client', 'true', 90 )
		}
		const noClientsAllowed = (el) => { 
				el.disabled = true
				localStorage.setItem('amp_pb_client', 'yes');
				alertNoClient()
		}
		const noClient = () => { 
				localStorage.removeItem('amp_pb_client');
				document.getElementById('client01').disabled = false
				const modal = document.getElementById('form--modal')
				modal.classList.remove('active')
		}

		const closeFormModal = () => { 
				const modal = document.getElementById('form--modal')
				modal.classList.remove('active')
		}
		
	 


		/* =============================
		 * Telefonvalidierung
		============================= */
		const checkTel = (el, err) => {
				const tel = document.getElementById(el).value;
				const error = document.getElementById(err);
				const check = /^[0-9()+/– -]+$/;
				if (check.test(tel) === false || tel.replace(/[^0-9]/g,"").length < 10) {
				error.classList.add("alert");
				error.innerHTML = "Saisissez un numéro de téléphone valide.";
				return false
				} else {
				error.classList.remove("alert");
				error.innerHTML = "";
				return true
				}
		};
		const checkTelInput = (el) => {
				const tel = document.getElementById(el).value;
				const input = document.getElementById(el)
				const check = /^[0-9()+/– -]+$/;
				if (check.test(tel) && tel.replace(/[^0-9]/g,"").length > 9) {
					input.parentNode.classList.add('success')
				} else { 
					input.parentNode.classList.remove('success')
				}
		};

 
		/* =============================
		 * Altersvalidierung
		============================= */
		const checkAge = (el, error) => {
				const age = document.getElementById(el).value;
				const err = document.getElementById(error);

				if (age < 1 || age > 125) {
				err.innerHTML = "Bitte gültiges Alter angeben";
				err.classList.add("alert");
				return false
				} else {
				err.classList.remove("alert");
				err.innerHTML = "";
				return true
				}
		};

		const checkAgeInput = (el) => {
				const age = document.getElementById(el).value;
				const input = document.getElementById(el)
				if (age > 0 && age < 126) {
				input.parentNode.classList.add('success')
				} else { 
				input.parentNode.classList.remove('success')
				}
		};

		/* =============================
		 * Plz validierung
		============================= */
		const checkPlz = (el, error) => {
				const plz = document.getElementById(el).value;
				const err = document.getElementById(error);
				if (plz.length != 4) {
				err.classList.add("alert");
				err.innerHTML = "Saisissez un code postal valide.";
				return false
				} else {
				err.classList.remove("alert");
				err.innerHTML = "";
				return true;
				}
		};

		const checkPlzInput = (el) => {
				const plz = document.getElementById(el).value;
				const input = document.getElementById(el)
				if (plz.length === 4) {
				input.parentNode.classList.add('success')
				} else { 
				input.parentNode.classList.remove('success')
				}
		};

		/* =============================
		 * E-Mail Validierung
		============================= */
		const checkEmail = (el, error) => {
				const email = document.getElementById(el).value;
				const err = document.getElementById(error);
				const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (
				reg.test(email) == false
				) {
				err.classList.add("alert");
				err.innerHTML = "Saisissez une adresse e-mail valide.";
				return false
				} else {
				err.classList.remove("alert");
				err.innerHTML = "";
				return true
				}
		};

		const checkEmailInput = (el) => {
				const email = document.getElementById(el).value;
				const input = document.getElementById(el)
				//formData.clearInteractionTimer()
				const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if (reg.test(email)) {
				input.parentNode.classList.add('success')
				} else { 
				input.parentNode.classList.remove('success')
				}
			
		};


	const isCheckboxChecked = (el, error, msg) => { 
		const checkbox = document.getElementById(el)
		const err = document.getElementById(error);
		if (checkbox.checked) {
			err.classList.remove("alert");
			err.innerHTML = "";
			return true
		} else { 
			err.classList.add("alert");
			err.innerHTML = msg;
			return false
		}
	}

	const resetForm = () => {
		document.querySelector('.showstopper').classList.remove('active')
		setQuestion(0)

	}

	const showStopper = () => {
		showQuestion(0)
		document.querySelectorAll('.question.active').forEach(q => q.classList.remove('active'))
		document.querySelector('.showstopper').classList.add('active')
	}

	function getValueFromRadioButton(name) {
		//Get all elements with the name
		var buttons = document.getElementsByName(name);
		for (var i = 0; i < buttons.length; i++) {
			//Check if button is checked
			var button = buttons[i];
			if (button.checked) {
				//Return value
				return button.value;
			}
		}
		//No radio button is selected.
		return null;
	}

//============================================
// Bereits Kunde
//============================================
		client.forEach(c => { 
				c.addEventListener('click', e => { 
						if (c.checked) {
								document.querySelector('.question.active').style.opacity = '0.5'
								setTimeout(() => {
										document.querySelector('.question.active').style.opacity = '1'
										const ls = localStorage.getItem('amp_pb_client');
		
										if (ls) { return }

										nextQuestion()
					
							}, 800)
		
								}
				})
		})
		
//============================================
// Welche Kampagne
//============================================
		studie.forEach(c => { 
				c.addEventListener('click', e => { 
						if (c.checked) {
								document.querySelector('.question.active').style.opacity = '0.5'
								setTimeout(() => {
										document.querySelector('.question.active').style.opacity = '1'
										const ls = localStorage.getItem('amp_pb_client');
		
										if (ls) { return }

										nextQuestion()
					
							}, 800)
		
								}
				})
		})
		
//============================================
// Für wen suchen Sie
//============================================
		
		cbWer.forEach(cb => {
				cb.addEventListener('click', e => { 

					if (document.getElementById('test')) { 
						//Testplatz entfernen
					document.getElementById('test').style.display = 'none'
					}

						if (cb.checked) { 
						if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
						document.querySelector('.question.active').style.opacity = '0.5'
						setTimeout(() => {
								document.querySelector('.question.active').style.opacity = '1'
								
							nextQuestion()
	
							//Route einblenden
							if (cb.value === "Pour moi") {
									route1.forEach(r => r.style.display = 'block')
									route2.forEach(r => r.style.display = 'none')
							} else { 
									route2.forEach(r => r.style.display = 'block')
									route1.forEach(r => r.style.display = 'none')
							}
	
					
	
						

						}, 800)

						}
				})
		 })
	

//============================================
// Krankenversicherung
//============================================
		
cbPKV.forEach(cb => {
				cb.addEventListener('click', e => { 

					if (document.getElementById('test')) { 
						//Testplatz entfernen
					document.getElementById('test').style.display = 'none'
					}

						if (cb.checked) { 
						if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
						document.querySelector('.question.active').style.opacity = '0.5'
						setTimeout(() => {
								document.querySelector('.question.active').style.opacity = '1'
								
							nextQuestion()
						}, 800)

						}
				})
		 })
	



//============================================
// Hörgeräteträger
//============================================
		
		cbMuehe.forEach(cb => { 
				cb.addEventListener('click', e => { 
					if (cb.checked) {
					if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
						document.querySelector('.question.active').style.opacity = '0.5'
						setTimeout(() => {
							document.querySelector('.question.active').style.opacity = '1'
							if (cb.value === 'Non') {
								showStopper()
							} else {
								nextQuestion()
							}

					}, 800)

						}

				})
	})
	
//============================================
// Sprachprobleme
//============================================
	if (cbProbleme) {
		
		cbProbleme.forEach(cb => { 
			cb.addEventListener('click', e => { 
				console.log(e.target.value);
				if (cb.checked) {
				document.querySelector('.question.active').style.opacity = '0.5'
				setTimeout(() => {
					document.querySelector('.question.active').style.opacity = '1'
				
	
					if (cb.value === 'Jamais') {
						showStopper()
					// formData.disableBulletClick(2)
					} else {
						nextQuestion()
					// formData.enableBulletClick(2)
					}
	
					
				}, 800)
	
				}
	
			})
		})
	}

	//============================================
	// Alter Hörgeräte
	//============================================

		cbAlterHG.forEach(cb => { 
				cb.addEventListener('click', e => {
						if (cb.checked) {
								if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }

					document.querySelector('.question.active').style.opacity = '0.5'

					setTimeout(() => {
						document.querySelector('.question.active').style.opacity = '1'

					nextQuestion()

					

				}, 800)
				}
			})
		})

	//============================================
	// Code
	//============================================

	if (btnCode) {
		
		btnCode.addEventListener("click", () => checkHoerzuCode('code'));
			
			 // Alter onKeyup
		codeField.addEventListener('keypress', e => {
			e.key === 'Enter' && e.preventDefault()
			e.key === 'Enter' && checkHoerzuCode('code')
			e.key === 'Enter' && plz.focus()
		})
	}



	//============================================
	// Alter
	//============================================

	const validateAlter = () => {
			if (checkAge("alter", "alertAlter")) { 
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
			nextQuestion()
			console.log('Tracking Alter');
			
		}
	}
	if( btnAlter ) btnAlter.addEventListener("click", validateAlter);
	if (alter) {
		// Alter onKeyup
   alter.addEventListener("keyup", () => checkAgeInput("alter"));
   alter.addEventListener("blur", () => checkAgeInput("alter"));
   alter.addEventListener('keypress', e => {
	   e.key === 'Enter' && e.preventDefault()
	   e.key === 'Enter' && validateAlter()
	   e.key === 'Enter' && plz.focus()
   })
		
	}	

	//============================================
	// PLZ
	//============================================

	const validatePLZ = () => { 
			if (checkPlz("plz", "alertPlz") && plz.value.length > 0) { 
				if(form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) alertNoClient()
			nextQuestion()
			
		}	
	}

		btnPlz.addEventListener("click", validatePLZ);
		// PLZ onKeyup
		plz.addEventListener("keyup", () => checkPlzInput("plz"));
		plz.addEventListener("blur", () => checkPlzInput("plz"));
	plz.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && validatePLZ()
		e.key === 'Enter' && tel.focus()
	})


	//============================================
	// Telefon
	//============================================

	const validateTel = () => {
			if (checkTel("tel", "alertTel")) {
				if(form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) alertNoClient()
			nextQuestion()

			console.log('Tracking Telefon');
			
		 }
	}

	btnTel.addEventListener("click", validateTel);
		// Tel onKeyup
		tel.addEventListener("keyup", () => checkTelInput("tel"));
		tel.addEventListener("blur", () => checkTelInput("tel"));
	tel.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && validateTel()
		e.key === 'Enter' && vorname.focus()
	})
	

	//============================================
	// Name
	//============================================

	const validateName = () => {
			if (isRadioChecked('anrede', 'alertanrede', 'Sélectionner civilité') && checkStringLength("vorname", "alertvorname", 2, "Indiquez un nom.") && checkStringLength("name", "alertName", 2, "Indiquez un nom.")) { 
				if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
			nextQuestion()
			console.log('Tracking Name');

		}
	}

	btnName.addEventListener("click", validateName);
		
	 // Name onKeyup
	nachname.addEventListener("keyup", () => checkStringLengthInput("name", 2));
	nachname.addEventListener("blur", () => checkStringLengthInput("name", 2));
	
	 // Vorname onKeyup
	 vorname.addEventListener("keyup", () => checkStringLengthInput("vorname", 2));
	 vorname.addEventListener("blur", () => checkStringLengthInput("vorname", 2));

	nachname.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && validateName()
		e.key === 'Enter' && mail.focus()
	})
	vorname.addEventListener('keypress', e => {
		e.key === 'Enter' && e.preventDefault()
		e.key === 'Enter' && nachname.focus()
	})


	//============================================
	// E-Mail
	//============================================


	const validateEmail = () => { 
			//formData.clearInteractionTimer()
			
			if (checkEmail("mail", "alertEmail") &&
			isCheckboxChecked('check', 'alertEmail', "Merci d'accepter les conditions de protection des données !")) {
					if (document.getElementById('checksession')) {
							if (isCheckboxChecked('checksession', 'alertEmail', 'Bitte alle Felder akzeptieren')) {
								if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
					console.log('Tracking Senden');
					form.submit();
				}
			} else { 
				console.log('Tracking Senden');
				form.submit();
			}			
		}
	}

	btnMail.addEventListener("click", e => {
			e.preventDefault();
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
		validateEmail()		
	});

	form.addEventListener('submit', e => { 
		e.preventDefault();
			if (form.dataset.ls == 'true' && localStorage.getItem('amp_pb_client')) { alertNoClient(); return }
		validateEmail();
	})

	mail.addEventListener('keyup', () => checkEmailInput('mail'))
	mail.addEventListener('blur', () => checkEmailInput('mail'))
	mail.addEventListener('keypress', e => e.key === 'Enter' && validateEmail())

		formData.bulletNavigation('.question');
	
}