if (document.getElementById('date')) { 
    const dateContent = document.getElementById('date')
    const d = new Date()
    d.setDate(d.getDate() - 3)
    const day = d.getDate()
    const month = d.getMonth()
    const year = d.getFullYear()
    const dayName = d.getDay()
    const weekDays = ['dimanche', 'lundi', 'mardi', 'mercedi', 'jeudi', 'vendredi', 'samedi']
    const months = [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre'
    ]
    const monthName = months[month]
    const weekDayName = weekDays[dayName]
    const dayOutput = `${weekDayName} ${day} ${monthName} ${year}`
    dateContent.innerHTML = dayOutput
}